// COMPONENTS
import React from 'react'
import Section from 'layout/Section'
import MarkdownBody from 'elements/MarkdownBody'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

// Banner component
const Banner = ({ content }) => {
  const {
    section,
    section: {inverted},
    title,
    subtitle,
    bodyMd,
    button,
    // buttonText,
    // buttonLink,
    imageUrl,
    videoUrl,
  } = content
  return (
    <Section content={section}>
      <Container fluid>
        <Row>
          <Col className='pb-4' md={6} xs={12}>
            <Row>
              <Col className='text-left mb-4' xs={12}>
                { title && <h1 className='display-3'>{title}</h1> }
                { subtitle && <h2 className='display-6'>{subtitle}</h2> }
              </Col>
              <Col className='mb-4' xs={12}>
                { bodyMd && <MarkdownBody markdown={bodyMd} inverted={inverted} /> }
              </Col>
              { button &&
              <Col className='text-center' xs={12}>
                <Button className='btn-info btn-lg btn-block' href={button.link}>
                  {button.text}
                </Button>
              </Col>
              }
            </Row>
          </Col>
          <Col className='d-flex align-items-center justify-content-center mb-4' md={6} xs={12}>
            {imageUrl && 
            <img className='rounded mx-auto d-block' src={imageUrl} alt='banner' style={{ width: '80%' }} />
            }
            {videoUrl && 
            <video className='rounded' width='100%' playsinline autoplay muted loop controls>
              <source src={videoUrl} type='video/mp4'/>
            </video>
            }
          </Col>
        </Row>
        {/* <Row className='justify-content-center'>
        <Col md={6}>
          <Button className='btn-info btn-lg btn-block'>{buttonText}</Button>
        </Col>
      </Row> */}
      </Container>
    </Section>
  )
}

export default Banner
