// COMPONENTS
import React from 'react'
import { Link } from 'gatsby'
import Section from 'layout/Section'
import MarkdownBody from 'elements/MarkdownBody'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
// ICONS
import Icon from 'elements/Icon'

// Features component
const Features = ({ content }) => {
  const { section, section: {inverted}, featureList, button } = content
  return (
    <Section content={section}>
      <Container>
        <Row className='justify-content-center' >
          <Col className='mt-5' xs={10}>
            {featureList && featureList.map(feature => (
              <Row className='justify-content-center mb-2' key={feature.title}>
                <Col className='align-items-center d-flex' md={2} xs={12}>
                  <div className='mx-auto' style={{ maxHeight: '200px' }}>
                    <Link to={feature.link} target='_blank' rel='noreferrer'>
                    { feature.icon ? 
                        <Icon name={feature.icon} /> :
                        <img
                          className='rounded mx-auto d-block'
                          src={feature.image}
                          alt='icon'
                          style={{maxHeight: '200px', maxWidth:'100%'}}
                        />
                      }
                    </Link>
                  </div>
                </Col>
                <Col
                  className='text-md-left text-sm-center mb-4'
                  md={10}
                  xs={12}>
                  <a href={feature.link} target='_blank' rel='noreferrer'>
                    <h3>{feature.title}</h3>
                    <MarkdownBody
                      markdown={feature.description}
                      inverted={inverted}
                    />
                  </a>
                  {/* <p>{feature.description}</p> */}
                </Col>
              </Row>)
            )}
          </Col>
          {button && (
            <Col className='text-center' xs={6}>
              <Button
                className={`btn-${button.color} btn-lg btn-block rounded`}
                href={button.link}>
                {button.text}
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </Section>
  )
}

export default Features
