// COMPONENTS
import React from 'react'
import Layout from 'layout/Layout'
import SEO from 'layout/SEO'
// SECTIONS
import Banner from 'LandingPage/Banner'
import Profile from 'LandingPage/Profile'
import Features from 'LandingPage/Features'
import Pricing from 'LandingPage/Pricing'
import FAQ from 'LandingPage/FAQ'
import Reviews from 'LandingPage/Reviews'
import Socials from 'LandingPage/Socials'
// USER SIGNUP/CONTACT
import SignUp from 'components/SignUp'
import MessageDrop from 'components/SignUp/MessageDrop'

// Flexible landing page template
const LandingPage = ({ content }) => {
  const {
    layout,
    seo,
    banner,
    profile,
    features,
    pricing,
    faq,
    reviews,
    socials,
    signup,
    messageDrop
  } = content
  return (
    <Layout header={layout.header}>
      <SEO title={seo.title} image={seo.imageUrl} />
      { banner && <Banner content={banner} /> }
      { profile && <Profile content={profile} /> }
      { features && <Features content={features} /> }
      { pricing && <Pricing content={pricing} /> }
      { faq && <FAQ content={faq} />}
      { reviews && <Reviews content={reviews} /> }
      { socials && <Socials content={socials} /> }
      { signup && <SignUp content={signup} /> }
      { messageDrop && <MessageDrop content={messageDrop} /> }
    </Layout>
  )
}

export default LandingPage
