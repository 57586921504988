// COMPONENTS
import React from 'react'
// OVERRIDE for general landing page
// import LandingPage from '../templates/LandingPage'
import LandingPage from 'templates/LandingPage'
// CONTENT
// import content from 'content/home.yml'
import content from 'content/landingPage.yml'

// Home page
const HomePage = () => {
  return <LandingPage content={content} />
}

export default HomePage
